import { ButtonDropdown, DateDisplay, Divider, FileDownload, Flag, Grid, Icon, LabelTooltip, Menu, Space, Status, Table, TableFilters, Text } from "components";
import { TableColumnsType } from "components/Table";
import ColumnsDisplay, { MixedColumn, tableColumnFilter, TableName, useColumnsDisplay } from "components/Table/ColumnsDisplay";
import { TableFilterField } from "components/TableFilters";
import { RecipientProfile } from "features/recipient";
import { StatusTaxForm, TaxFormActions, TaxFormDisplay } from "features/taxForm";
import { UserProfile } from "features/user";
import { useWhiteLabelWarning } from "features/whiteLabel";
import TaxDocumentPreview from "pages/RecipientsPage/Details/Tax/TaxDocumentPreview";
import TaxWarning from "pages/RecipientsPage/Details/Tax/TaxWarning";
import UploadTaxDocument from "pages/RecipientsPage/Details/Tax/UploadTaxDocument";
import React, { ReactNode, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { approveRecipientTaxForm, WithholdingReasonLabels } from "store/actions/recipientTaxForms";
import { TaxForm, TaxFormsQuery, UsUpload, W8BENForm } from "store/actions/taxForms";
import { useShallowSelector } from "store/hooks";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useAccess } from "store/hooks/user";
import { BaseStatus, ListState } from "store/reducers/standardReducer";
import styled from "style/classname";
import { CATEGORY_DETAILS } from "utils/constants";
import { Access, BaseError, PaymentCategory, RecipientStatus, TaxFormType, TaxStatus } from "@trolley/common-frontend";
import VoidFormPopup from "./VoidFormPopup";
import getFTinLabel from "utils/helpers/ftins";

const styledTableRow = styled`
  height: 60px !important;
`();

export enum TaxFilterKeys {
  STATUS = "status",
  KIND = "kind",
  TIN_STATUS = "tinStatus",
  ID = "id",
  FTIN_STATUS = "ftinStatus",
}

enum ColumnKeys {
  STATUS = "status",
  RECIPIENT = "recipientId",
  KIND = "kind",
  TIN_STATUS = "tinStatus",
  FTIN_TYPE = "fTinType",
  WITHHOLDING = "withholding",
  SIGNED_AT = "signedAt",
  UPDATED_AT = "updatedAt",
  ACTIONS = "actions",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.STATUS]: true,
  [ColumnKeys.RECIPIENT]: true,
  [ColumnKeys.ACTIONS]: true,
  [ColumnKeys.KIND]: {
    label: "Tax Form Type",
    value: true,
  },
  [ColumnKeys.TIN_STATUS]: {
    label: "US TIN Status",
    value: true,
  },
  [ColumnKeys.FTIN_TYPE]: {
    label: "FTIN Type",
    value: true,
  },
  [ColumnKeys.WITHHOLDING]: {
    label: "Withholding Rate",
    value: true,
  },
  [ColumnKeys.SIGNED_AT]: {
    label: "Signed On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
};

enum ButtonActions {
  APPROVE = "approve",
  VOID = "void",
  VOID_SOLICIT = "void-solicit",
  EDIT = "edit",
}

const FIELDS: TableFilterField[] = [
  {
    label: "Form Status",
    filterKey: TaxFilterKeys.STATUS,
    type: "select",
    options: [
      { label: "Incomplete", value: TaxStatus.INCOMPLETE },
      { label: "Submitted", value: TaxStatus.SUBMITTED },
      { label: "Reviewed", value: TaxStatus.REVIEWED },
      { label: "Expired", value: TaxStatus.EXPIRED },
      { label: "Voided", value: TaxStatus.VOIDED },
    ],
  },
  {
    label: "Form Type",
    filterKey: TaxFilterKeys.KIND,
    type: "select",
    options: [
      { label: "W-9", value: TaxFormType.W9 },
      { label: "W-8BEN", value: TaxFormType.W8BEN },
      { label: "W-8BEN-E", value: TaxFormType.W8BENE },
      { label: "Uploaded Form", value: TaxFormType.US_UPLOAD },
    ],
  },
  {
    label: "US TIN Status",
    filterKey: TaxFilterKeys.TIN_STATUS,
    type: "select",
    options: [
      { label: "New", value: "new" },
      { label: "Pending", value: "pending" },
      { label: "Not Valid", value: "not_valid" },
      { label: "Valid", value: "valid" },
    ],
  },
  {
    label: "Tax Form IDs",
    filterKey: TaxFilterKeys.ID,
    type: "tags",
    maxLength: 1,
    placeholder: "Tax Form ID",
  },
  {
    label: "FTIN Status",
    filterKey: TaxFilterKeys.FTIN_STATUS,
    type: "select",
    options: [
      { label: "Valid", value: "valid" },
      { label: "Invalid", value: "not_valid" },
      { label: "Missing", value: "missing" },
    ],
  },
];

function getExcludedColumns(showRecipients: boolean, accessTaxWrite: boolean) {
  const cols: ColumnKeys[] = [];

  if (!showRecipients) {
    cols.push(ColumnKeys.RECIPIENT);
  }

  if (!accessTaxWrite) {
    cols.push(ColumnKeys.ACTIONS);
  }

  return cols;
}

interface Props {
  taxForms: ListState<TaxForm>;
  status?: BaseStatus;
  errors?: BaseError[];
  showRecipients?: boolean;
  showFilters?: boolean;
  query: TaxFormsQuery;
  tableHeader?: ReactNode;
  onChangeQuery(changes: TaxFormsQuery): void;
}

export default function TaxFormsTable(props: Props) {
  const { taxForms, query, showRecipients = true, showFilters, status, errors, tableHeader } = props;
  const accessRecipientWrite = useAccess(Access.RECIPIENT_WRITE);
  const accessTaxWrite = useAccess(Access.TAX_WRITE);
  const [previewForm, setPreviewForm] = useState<TaxForm | undefined>();
  const [editUploadedForm, setEditUploadForm] = useState<TaxForm | undefined>();
  const [selected, setSelected] = useState<TaxForm[]>([]);
  const [voidForm, setVoidForm] = useState<{ taxForms: TaxForm[]; solicit: boolean } | undefined>();
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.TAX_FORMS,
    defaultColumnsDisplay,
    getExcludedColumns(showRecipients, accessTaxWrite),
  );
  const { data: merchant } = useMerchantSettings();
  const { whiteLabel, warning } = useWhiteLabelWarning();
  const recipientEntities = useShallowSelector((state) => state.recipient.entities);
  const defaultCategory: PaymentCategory = merchant?.payment?.defaultCategory || PaymentCategory.ROYALTIES;
  const selectedApprovable = selected.filter(
    (t) =>
      t.status === TaxStatus.SUBMITTED &&
      recipientEntities[t.recipientId]?.status &&
      ![RecipientStatus.ARCHIVED, RecipientStatus.BLOCKED].includes(recipientEntities[t.recipientId]?.status as RecipientStatus),
  );
  const selectedVoidable = selected.filter((t) => t.status !== TaxStatus.VOIDED);
  const selectedVoidableSolicit = selected.filter(
    (t) =>
      t.status !== TaxStatus.VOIDED &&
      recipientEntities[t.recipientId]?.status &&
      ![RecipientStatus.ARCHIVED, RecipientStatus.BLOCKED].includes(recipientEntities[t.recipientId]?.status as RecipientStatus),
  );

  function renderWithholding(form: TaxForm) {
    const withholdings = form.calculatedWithholdings;
    const incomeCode = CATEGORY_DETAILS[defaultCategory]?.incomeCode;
    const defaultWithholding = incomeCode && withholdings[incomeCode];
    const enabledCategories = Object.entries(merchant?.payment?.categories || {})
      .filter(([category, enabled]) => enabled && CATEGORY_DETAILS[category]?.incomeCode)
      .map(([category, _]) => category) as PaymentCategory[];
    const categoriesWithoutDefault = enabledCategories.filter((cat) => cat !== defaultCategory && CATEGORY_DETAILS[cat]?.incomeCode);

    return defaultWithholding ? (
      <>
        {defaultWithholding.withholdingPercentage}%
        {enabledCategories.length > 1 && (
          <Icon
            type="circle-info"
            color="grey"
            right
            tooltipProps={{ placement: "topRight" }}
            tooltip={
              <div>
                <Text padded>
                  <strong>{defaultWithholding.withholdingPercentage}%</strong> for {CATEGORY_DETAILS[defaultCategory].name}
                </Text>

                <Text size="small">{WithholdingReasonLabels[defaultWithholding.reason[0]] || defaultWithholding.reason[0]}</Text>
                {categoriesWithoutDefault.length > 0 && (
                  <>
                    <Divider margin="small" />
                    <ul style={{ paddingLeft: "19px" }}>
                      {categoriesWithoutDefault.map((cat) => (
                        <li key={cat}>
                          <Text size="small">
                            <strong>{withholdings[CATEGORY_DETAILS[cat].incomeCode || ""]?.withholdingPercentage}%</strong> for {CATEGORY_DETAILS[cat].name}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            }
          />
        )}
      </>
    ) : (
      <span>{form.withholding}%</span>
    );
  }

  function onAction(mainAction: ButtonActions) {
    if (mainAction === ButtonActions.VOID_SOLICIT) {
      setVoidForm({ taxForms: selectedVoidableSolicit, solicit: true });
    } else if (mainAction === ButtonActions.VOID) {
      setVoidForm({ taxForms: selectedVoidable, solicit: false });
    } else if (mainAction === ButtonActions.APPROVE && selectedApprovable.length > 0) {
      Promise.allSettled(selectedApprovable.map((f) => approveRecipientTaxForm(f.recipientId, f.id)))
        .then((settled) => {
          notifySuccess(`${settled.filter((s) => s.status === "fulfilled").length} tax forms marked as reviewed`);
          setSelected([]);
        })
        .catch(() => {
          notifyError("Approving tax forms failed");
        });
    }
  }

  function getMenu() {
    const enableApprove = selectedApprovable.length > 0;
    const enableVoid = selectedVoidable.length > 0;
    const enableVoidSolicit = selectedVoidableSolicit.length > 0;

    return (
      <ButtonDropdown
        onClick={() => {
          onAction(ButtonActions.APPROVE);
        }}
        overlay={
          <Menu
            items={[
              {
                key: ButtonActions.APPROVE,
                disabled: !enableApprove,
                onClick: () => {
                  onAction(ButtonActions.APPROVE);
                },
                label: (
                  <>
                    <Icon type="file-check" color={enableApprove ? "blue" : undefined} left />
                    Mark as Reviewed ({selectedApprovable.length})
                  </>
                ),
              },
              accessRecipientWrite && whiteLabel?.taxRequired
                ? {
                    key: ButtonActions.VOID_SOLICIT,
                    disabled: !(enableVoidSolicit && !warning),
                    onClick: () => {
                      onAction(ButtonActions.VOID_SOLICIT);
                    },
                    label: (
                      <>
                        <Icon type="file-slash" left color={enableVoidSolicit && !warning ? undefined : "grey"} />
                        Void & Request Tax Forms ({selectedVoidableSolicit.length}){warning && <Icon.Status type="warning" right tooltip={warning} />}
                      </>
                    ),
                  }
                : null,
              {
                key: ButtonActions.VOID,
                disabled: !enableVoid,
                onClick: () => {
                  onAction(ButtonActions.VOID);
                },
                label: (
                  <>
                    <Icon type="file-slash" left color={enableVoid ? undefined : "grey"} />
                    Void Tax Forms ({selectedVoidable.length})
                  </>
                ),
              },
            ]}
          />
        }
      >
        Mark as Reviewed ({selectedApprovable.length})
      </ButtonDropdown>
    );
  }

  function renderFTINTypeColumn(tf: TaxForm) {
    const { taxFormType, taxFormData } = tf;
    if (![TaxFormType.W8BENE, TaxFormType.W8BEN].includes(taxFormType as TaxFormType)) return "-";
    const { ftinType, residenceCountry } = taxFormData as W8BENForm;
    if (!residenceCountry) return "-";

    return (
      <Space>
        <Flag code={residenceCountry} showLabel={false} />
        {getFTinLabel(residenceCountry, ftinType)}
      </Space>
    );
  }

  const columns: TableColumnsType<TaxForm> = (
    [
      {
        key: ColumnKeys.STATUS,
        title: "Form Status",
        width: 100,
        dataIndex: "status",
        align: "center",
        render: (status: string, tf: TaxForm) => <StatusTaxForm taxForm={tf} showTooltipWarnings />,
      },
      {
        key: ColumnKeys.RECIPIENT,
        title: "Recipient",
        dataIndex: "recipientId",
        render: (id: TaxForm["recipientId"]) => <RecipientProfile size="small" recipientId={id} showLink showAddress showStatus="dot" />,
      },
      {
        key: ColumnKeys.KIND,
        title: "Tax Form Type",
        dataIndex: "id",
        render: (id: TaxForm["id"], tf: TaxForm) => {
          return (
            <>
              <TaxFormDisplay taxFormId={id} />
              <FileDownload
                url={`/v1/recipients/${tf.recipientId}/tax/${id}/download`}
                fileName={`PR_${tf.recipientId}_taxform_${id}`}
                tooltip="Download PDF"
                icon
                right
              />
            </>
          );
        },
      },
      {
        key: ColumnKeys.TIN_STATUS,
        title: "US TIN Status",
        dataIndex: "tinStatus",
        render: (status: string, tf: TaxForm) =>
          tf.taxFormType === TaxFormType.W9 || (tf.taxFormData as W8BENForm)?.taxPayerUsId || (tf.data as UsUpload)?.taxPayerUsId ? (
            <Text capitalize>
              <Status dot type={status} left />
              {String(status).replace(/_/g, " ")}
            </Text>
          ) : (
            " - "
          ),
      },
      {
        key: ColumnKeys.FTIN_TYPE,
        title: "FTIN Type",
        render: renderFTINTypeColumn,
      },
      {
        key: ColumnKeys.WITHHOLDING,
        title: "Withholding Rate",
        align: "right",
        render: renderWithholding,
      },
      {
        key: ColumnKeys.SIGNED_AT,
        title: "Signed On (UTC)",
        dataIndex: "signedAt",
        render: (signedAt: string, tf: TaxForm) => (
          <>
            <DateDisplay value={signedAt} time={false} showUtc />
            {tf.signed && (
              <Text>
                by <UserProfile userId={tf.signed} style={{ display: "inline-flex" }} />
              </Text>
            )}
          </>
        ),
      },
      {
        key: ColumnKeys.UPDATED_AT,
        title: "Updated On",
        dataIndex: "updatedAt",
        render: (updatedAt: string) => <DateDisplay value={updatedAt} time={false} />,
      },
      {
        key: ColumnKeys.ACTIONS,
        title: <LabelTooltip type="formActions" />,
        fixed: "right",
        align: "right",
        width: 32,
        dataIndex: "id",
        render: (id: string, record) => (
          <Grid padding="xsmall" justify="space-between" align="middle" wrap={false}>
            <Grid.Item>{record.warnings.length > 0 && <Icon.Status type="error" size="large" tooltip={<TaxWarning taxForm={record} />} />}</Grid.Item>
            <Grid.Item>
              <TaxFormActions taxFormId={id} compact />
            </Grid.Item>
          </Grid>
        ),
      },
    ] as TableColumnsType<TaxForm>
  ).filter(tableColumnFilter(displayedColumns));

  return (
    <>
      <TableFilters
        showSearch={showFilters}
        fields={showFilters ? FIELDS : undefined}
        query={query}
        onChange={props.onChangeQuery}
        addOnAfter={tableHeader}
        suffix={
          <Space direction="row-reverse">
            <ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />
            {taxForms.records.length > 0 && (
              <FileDownload
                method="POST"
                url="/v1/tax-form/export"
                query={{ ...query, format: "csv" }}
                fileName={`PR_tax_forms_${Date.now()}`}
                defaultExtension="csv"
                tooltip="Export CSV"
                button
                icon="export"
              >
                Export
              </FileDownload>
            )}
          </Space>
        }
      >
        {selected.length > 0 ? getMenu() : null}
      </TableFilters>

      <Table<TaxForm>
        columns={columns}
        rowClassName={() => styledTableRow}
        dataSource={taxForms.records}
        rowSelection={
          showFilters && accessTaxWrite
            ? {
                selectedRowKeys: selected.map((tf) => tf.id),
                onChange: (keys: string[], selectedRows: TaxForm[] = []) => {
                  setSelected(selectedRows);
                },
              }
            : undefined
        }
        onRow={(tf) => {
          return {
            onClick: () => {
              setPreviewForm(tf);
            },
          };
        }}
        emptyProps={{
          description: "No tax forms submitted",
        }}
        onQueryChange={props.onChangeQuery} // need to handle sortBy[] and orderBy[]
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: taxForms.meta.records,
        }}
        status={status}
        errors={errors}
      />

      <UploadTaxDocument
        taxForm={editUploadedForm}
        onUpdate={setEditUploadForm}
        onClose={() => {
          setEditUploadForm(undefined);
        }}
      />

      <TaxDocumentPreview
        taxFormId={previewForm?.id}
        showActions
        onClose={() => {
          setPreviewForm(undefined);
        }}
      />

      <VoidFormPopup
        taxForms={voidForm?.taxForms}
        solicit={voidForm?.solicit}
        onClose={() => {
          setVoidForm(undefined);
          setSelected([]);
        }}
      />
    </>
  );
}
