import { Button, ButtonProps, Icon, Modal, Popconfirm, PopconfirmProps, TooltipProps } from "components";
import React from "react";

type Props = Omit<ButtonProps, "icon" | "type" | "onClick"> & {
  title: PopconfirmProps["title"];
  description?: PopconfirmProps["description"];
  confirmType?: "popover" | "modal";
  confirmPlacement?: TooltipProps["placement"];
  okText?: string;
  cancelText?: string;
  onConfirm(e: React.MouseEvent<HTMLButtonElement>): void | Promise<void>;
};

export default function ButtonDelete(props: Props) {
  const { title, description, okText = "Yes, Delete", cancelText, confirmType, confirmPlacement, onConfirm, children, danger = true, ...buttonProps } = props;
  const icon = <Icon type="trash" theme="solid" />;

  if (confirmType === "modal") {
    return (
      <Button
        danger={danger}
        onClick={(e) => {
          e?.stopPropagation?.();
          if (!buttonProps.disabled) {
            Modal.confirm({
              title,
              content: description,
              okText,
              cancelText,
              type: "delete",
              onOk: onConfirm,
            });
          }
        }}
        icon={icon}
        {...buttonProps}
      >
        {children}
      </Button>
    );
  }

  const confirmProps: PopconfirmProps = {
    title,
    description,
    placement: confirmPlacement,
    cancelText,
    okText,
    onConfirm,
    disabled: buttonProps.disabled,
    okType: "danger",
    icon: <Icon type="trash" color="red" size="xlarge" theme="solid" style={{ float: "left", margin: "4px 8px 8px 0" }} />,
  };

  return (
    <Popconfirm {...confirmProps}>
      <Button danger={danger} icon={icon} {...buttonProps}>
        {children}
      </Button>
    </Popconfirm>
  );
}
