import { Access, OnboardingType } from "@trolley/common-frontend";
import config from "config";
import { Features, MerchantSettings } from "store/actions/merchantSettings";
import { User } from "store/actions/user";
import { Userpilot } from "userpilot";

export function userpilotInit() {
  if (config.USERPILOT_APP_TOKEN) {
    Userpilot.initialize(config.USERPILOT_APP_TOKEN);
  }
}

export function userpilotBoot(user: User, merchantSettings: MerchantSettings, features: Partial<Features>) {
  if (config.USERPILOT_APP_TOKEN && !user.isImpersonated && !user.access.includes(Access.SUPER) && user.merchantId === merchantSettings.merchantId) {
    try {
      Userpilot.identify(user.id, {
        name: user.name,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        trolleyRole: user.role,
        role: user.role,
        created_at: user.createdAt,
        user_id: user.id,
        company: {
          name: merchantSettings.name,
          id: merchantSettings.merchantId,
          created_at: merchantSettings.createdAt,
          url: merchantSettings.website,
          business_country: merchantSettings.country,
          primary_currency: merchantSettings.primaryCurrency,
          status: merchantSettings.status,
          tax_enabled: !!features.tax && !!merchantSettings.tax?.enabled,
          dac7_enabled: !!features.euTax && !!merchantSettings.euTax?.enabled,
          parent_id: merchantSettings.parentMerchantId,
          type: getMerchantType(merchantSettings),
        },
      });

      return true;
    } catch {
      return false;
    }
  }

  return false;
}

export function userpilotReset() {
  if (config.USERPILOT_APP_TOKEN) {
    Userpilot.destroy();
  }
}

function getMerchantType(merchant: MerchantSettings): "parent" | "submerchant_heavy" | "submerchant_light" | "sandbox" {
  if (merchant.sandbox) {
    return "sandbox";
  }

  if (merchant.parentMerchantId) {
    return merchant.onboardingType === OnboardingType.LIGHT ? "submerchant_light" : "submerchant_heavy";
  }

  return "parent";
}
