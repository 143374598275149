import * as types from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";
import { BatchPaymentCreate, BatchPaymentUpdate } from "./batchPayments";

export { BatchStatus } from "@trolley/common-frontend";

export interface Batch extends types.Batch {}

export interface BatchesQuery extends Query<Batch> {
  search?: string;
  currency?: string[];
  status?: string[];
}

export function resetBatchList() {
  standardDispatch(OpCode.RESET, "batches");
}

export function loadBatches(options: BatchesQuery, force?: boolean) {
  const data = store.getState().batches;
  const id = computeID(options);

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "batches", {
      id,
    });

    request
      .POST<types.BatchListResult>("/v1/batches/search", { query: options })
      .then(({ body }) => {
        const batches: Mapped<Batch> = {};
        const records = body.batches.map((b) => {
          batches[b.id] = b;

          return b.id;
        });

        batch(() => {
          standardDispatch(OpCode.DATA, "batch", {
            bulk: batches,
          });
          standardDispatch(OpCode.DATA, "batches", {
            id,
            data: { records, meta: body.meta },
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "batches", {
          id,
          errors,
        });
      });
  }

  return id;
}

const batchQueueLoader = queueFactory(
  (batchIds) => {
    request
      .POST<types.BatchListResult>("/v1/batches/search", { query: { ids: batchIds, pageSize: batchIds.length } })
      .then(({ body }) => {
        const mappedBatches = body.batches.reduce((acc, b) => {
          acc[b.id] = b;

          return acc;
        }, {});

        standardDispatch(OpCode.DATA, "batch", {
          bulk: batchIds.reduce((acc, id) => {
            acc[id] = mappedBatches[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "batch", {
          ids: batchIds,
          errors,
        });
      });
  },
  (id) => /^B-\w+/.test(id),
);

export function loadBatch(batchId: string, force?: boolean) {
  const { batch } = store.getState();

  if (force || !isLoaded(batch.fetchStatus[batchId])) {
    standardDispatch(OpCode.LOADING, "batch", { id: batchId });
    batchQueueLoader.add(batchId);
  }
}

export async function deleteBatches(rawIds: string[]) {
  const ids = types.uniqueList(rawIds);
  await request.POST("/v1/batch/delete", { query: { id: ids } });

  standardDispatch(OpCode.UPDATE, "batch", {
    bulk: Object.fromEntries(ids.map((id) => [id, { status: types.BatchStatus.DELETED }])),
  });
  standardDispatch(OpCode.RESET, "invoicePayments");
}

// to improve the perfomance for CDBaby, Envato, Canva, we will skip generate-quote for them
export function shouldSkipQuote() {
  const merchantSkipQuote = [
    "M-HuhWXtdJqk24d4rHzAJLqZ", // cd baby
    "M-Gg1DeGKLMoYvEn94Jyaaok", // Audio & Video Labs, Inc, cd baby sandbox
    "M-7dTvgwjBrk9HM357sGp2hv", // Soundrop sandbox
    "M-QaMDaQ5gkprRqbgSacaw52", // Soundrop
    "M-5RAdNzT3WMgk3Pgfsvfgyv", // Soundrop sandbox
    "M-GQRJHwDkF3QPM6DPbfgzuR", // cd baby sandbox
    "M-JeAooVzDiLEjJxUrWYjWeE", // cd baby sandbox
    "M-QwbC9hAn1sFugiv6GCjnYR", // cd baby sandbox
    "M-UoUNLscSMdHoXXocpgKZuo", // Envato
    "M-VEcCTyBsnMwMWY3Hwb7Pdc", // Envato Sandbox
    "M-6mtgeuiwiqWpNECvKAKZga", // Canva
    "M-E6NZkEx5KbnEvzK1M2dB6Y", // Canva sandbox
    "M-MjWKHcQiSRWq3skz9TGWXm", // Canva sandbox
    "M-GVaieAnVp9uKefbJiTxmbi", // Envato Elements Pty Ltd
    "M-Xmr8ui92FBAct6ej63Y8ci", // Envato Pty Ltd
    "M-B8PmkEeJzMyAjgYydbGkUF", // Envato USA Inc
    "M-XXKv14Q8PN88ajSfefTWjm", // Bugcrowd
    "M-WDxQHcaeSj8or7HLGt8M7w", // Bugcrowd sandbox
    "M-JQZYfSqacZ5XoQNWtb66yn", // Bugcrowd dac7
    "M-2jGBnwukyPuvn2SSSdv3Eo", // prl ca merchant trolley-dev
    "M-QmQD3r7LeNj4E4pqCDd3PC", // prl ca merchant trolley-dev sandbox
    "M-NEpBkwMxyj8ANGs2T41YXk", // afxStagingMerchant trolley-dev
    "M-DDawHFLKD2NoRgMqSdDABY", // afxStagingMerchant trolley-dev sandbox
    "M-HzrSHf72UNuPBYwUaJMDJP", // prlprcamerchant staging
    "M-3T3KLoiVnNLvSB42MMgooh", // prlprcamerchant sandbox
    "M-CNfjW9Cf6TFsSR2JWT7TWc", // TROLLEY UK PROD
  ];

  const merchantGuid = store.getState().merchantSettings.entities.data?.merchantId;

  return merchantGuid && merchantSkipQuote.includes(merchantGuid);
}

export function getQuote(id: string) {
  if (shouldSkipQuote()) {
    // remove loading of the send out batch button.
    standardDispatch(OpCode.LOADING, "batch", { id, loading: false });

    return;
  }

  standardDispatch(OpCode.LOADING, "batch", { id });

  // Add delay for requote so wesocket queue has time to send payload in correct order
  window.setTimeout(async () => {
    if (store.getState().batch.entities[id]?.status === types.BatchStatus.OPEN) {
      /** Allow requote for open ONLY
       * Note: Requoting in Approval will reopen the batch.
       */

      try {
        const { body } = await request.POST<types.BatchResult>(`/v1/batches/${id}/generate-quote`);
        standardDispatch(OpCode.DATA, "batch", { id, data: body.batch });
      } catch (errors) {
        standardDispatch(OpCode.ERROR, "batch", { id, errors });
      }
    }
  }, 300);
}

export type BatchUpdate = {
  sourceCurrency?: string;
  description?: string;
  tags?: string[];

  payments?: BatchPaymentUpdate[];
};

export async function updateBatch(batchId: string, batch: BatchUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "batch", { id: batchId });
    const { body } = await request.PATCH<types.BatchResult>(`/v1/batches/${batchId}`, { query: batch });
    standardDispatch(OpCode.DATA, "batch", { id: batchId, data: body.batch });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "batch", {
      errors,
      id: batchId,
    });
    throw errors;
  }
}

export async function batchCreate(query?: { payments?: BatchPaymentCreate[]; sourceCurrency?: types.CurrencyCode; description?: string }) {
  try {
    const res = await request.POST<types.BatchResult>("/v1/batches", { query });

    standardDispatch(OpCode.DATA, "batch", {
      id: res.body.batch?.id,
      data: res.body.batch,
    });

    return res.body.batch?.id || "";
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "batch", { errors });
    throw errors;
  }
}

/**
 * Start batch when batch status is 'open' ONLY
 * @param batchId
 */
export async function batchStart(
  batchId: string,
  query?: {
    tfaOtp?: string;
    tfaType?: string | null;
  },
) {
  standardDispatch(OpCode.LOADING, "batch", {
    id: batchId,
  });
  try {
    const res = await request.POST<types.BatchResult>(`/v1/batches/${batchId}/start-processing`, { query });
    standardDispatch(OpCode.DATA, "batch", {
      id: batchId,
      data: res.body.batch,
    });

    if (res.body.batch.status === types.BatchStatus.PROCESSING) {
      batch(() => {
        standardDispatch(OpCode.RESET, "statsData");
        standardDispatch(OpCode.RESET, "ledgerItems");
      });
    }
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "batch", {
      errors,
      id: batchId,
    });
    throw errors;
  }
}
