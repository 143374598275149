import { Icon, Status, Text } from "components";
import { StatusProps } from "components/Status";
import dayjs from "dayjs";
import React from "react";
import { TaxForm } from "store/actions/taxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { TaxFormVoidReason, TaxStatus } from "@trolley/common-frontend";

type Props = Omit<StatusProps, "type" | "tooltip"> & {
  taxForm: TaxForm | undefined;
  showTooltipWarnings?: boolean;
};

export function InvalidTINWarnings(props: { taxForm: TaxForm | undefined }) {
  const { taxForm } = props;
  const { data: merchantSettings } = useMerchantSettings();

  if (taxForm?.tinStatus === "not_valid") {
    const daysDiff = dayjs().diff(taxForm.tinValidatedAt || "", "days");

    return (
      <Text>
        TIN matching validation failed {daysDiff} {daysDiff === 1 ? "day" : "days"} ago.
        {merchantSettings?.tax?.enabled && merchantSettings.tax.applyTinDefaultWithholding && (
          <div data-test="tin-warning">
            <Icon type="circle-info" color="blue" theme="solid" left />
            24% Backup Withholding will apply after 30 days
          </div>
        )}
      </Text>
    );
  }

  return null;
}

export default function StatusTaxForm(props: Props) {
  const { taxForm, showTooltipWarnings, ...rest } = props;

  if (!taxForm) {
    return null;
  }

  const voidReason = taxForm.status === TaxStatus.VOIDED && taxForm.voidReason;

  let tooltip;

  if (showTooltipWarnings) {
    if (voidReason) {
      tooltip = (
        <>
          <Text type="label">Tax Form Voided Reason:</Text>
          {voidReasonText[voidReason] || voidReason}
        </>
      );
    } else if (taxForm?.tinStatus === "not_valid") {
      tooltip = <InvalidTINWarnings taxForm={taxForm} />;
    }
  }

  return (
    <Status
      {...rest}
      tooltipProps={tooltip ? { title: tooltip } : undefined}
      type={taxForm.status}
      colors={
        showTooltipWarnings && !voidReason && taxForm.tinStatus === "not_valid"
          ? {
              [taxForm.status]: dayjs()
                .subtract(30, "days")
                .isAfter(taxForm.tinValidatedAt as string, "days")
                ? "red"
                : "yellow",
            }
          : undefined
      }
    />
  );
}

export const voidReasonText: Record<TaxFormVoidReason, string> = {
  [TaxFormVoidReason.NEW_SUBMISSION]: "A new tax form was submitted",
  [TaxFormVoidReason.INVALID_DATA]: "Invalid Information",
  [TaxFormVoidReason.INVALID_SIGNATURE]: "Invalid Signature",
  [TaxFormVoidReason.INVALID_TIN]: "Invalid Tax Identification Number",
};
